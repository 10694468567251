$white: #ffffff;
$black: #000000;
$black2: #1C2223;
$black3: #262626;

$gray: #90949A;
$gray2: #C3C8CC;
$gray3: #707378;
$gray4: #A9AEB2;
$gray5: #E5E5E5;
$red: #EB5757;
$red2: #FF5E73;
$red3:  #FFF2F4;

$bg: #E2F5FF;
$bg2:  #F6F7F9;
$bord: #F0F0F0;

$blue:  #51D0DD;
$blue2:  #E5F9FF;
$blue3: #079DB7;
$blue4: #B9ECF1;
$blue5: #EFFAFE;

/*@mixin tx {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: $black;
}

@include tx;*/

$font-family: 'Montserrat', sans-serif;
$font-family2: 'Comfortaa', sans-serif;






