@import "variables.scss";


* {
  margin: 0;
  padding: 0;
}


body {
  min-width: 320px;

  font-family: $font-family;

  &.overfl {
    overflow: hidden;
    height: 100vh;
  }

  &.bg {
    background: $bg2;

    @media screen and(max-width: 991px) {
      background: $blue;
    }
  }
}

.start-page {
  padding: 30px;

  &-logo {
    display: block;
    margin-bottom: 30px;
  }

  ul {
    display: inline-block;
    vertical-align: top;
    padding-right: 60px;

    li {
      padding: 5px 0;

      b {
        font-size: 20px;
      }

      a {
        font-size: 16px;
        line-height: 140%;
        color: $black;

        &:hover {
          //color: $active;
        }

        .red {
          color: red;
        }
      }
    }
  }
}


div,
span,
a,
input,
textarea,
select,
button,
.button {
  &:focus {
    outline: none;
  }
}

input,
textarea {
  box-shadow: none!important;
}


ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

a {
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

svg {
  use {
    transition: 0.3s;
  }
}

.container {
  max-width: 100%;

  @media screen and(min-width: 1490px){
    max-width: 1460px;
  }
}


.button {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;

  &-primary {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 60px;

    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $white;
    border-radius: 12px;
    background: $blue;

    img {
      margin-right: 10px;
    }

    &:disabled {
      background: $blue4!important;
      cursor: default;
    }

    @media screen and(max-width: 991px){
      height: 55px;
    }

    &:hover {
      background: $blue3;
      color: $white;
    }
  }

  &-secondary {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 60px;

    background: $blue2;
    border-radius: 12px;
    color: $blue3;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    &:hover {
      background: $blue;
      color: $white;
    }
  }

  &-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 60px;

    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $black2;
    background: transparent;

    &:hover {
      color: $blue3;
    }
  }
}

.logo {
  display: inline-block;

  .enter & {
    margin: 0 auto 0 23.5%;

    @media screen and(max-width: 1575px){
      margin-left: 15%;
    }

    @media screen and(max-width: 1400px){
      margin-left: 5%;
    }

    @media screen and(max-width: 1250px){
      img {
        max-width: 180px;
      }
    }

    @media screen and(max-width: 991px){
      margin: 0 auto;
      display: table;

      img {
        max-width: 171px;
      }
    }
  }

  .header & {
    width: 178px;

    img {
      max-width: 100%;
    }
  }
}

h1 {
  margin-bottom: 20px;

  font-weight: 700;
  font-size: 46px;
  line-height: 135%;
  color: $black2;
  font-family: $font-family2;

  @media screen and(max-width: 1250px){
    font-size: 36px;
  }
}

.enter {
  display: flex;
  height: 100vh;
  min-height: 800px;

  @media screen and(max-width: 991px){
    padding: 84px 20px 160px;
    height: auto;
    min-height: inherit;
    display: block;

    position: relative;

    &:before {
      content: '';
      width: 59px;
      height: 35px;
      display: block;

      position: absolute;
      bottom: 35px;
      left: 57px;

      background-image: url("../images/svg/line-min1.svg");
      background-repeat: no-repeat;
    }
    &:after {
      content: '';
      width: 95px;
      height: 93px;
      display: block;

      position: absolute;
      bottom: 20px;
      right: 32px;

      background-image: url("../images/svg/line-min2.svg");
      background-repeat: no-repeat;
    }
  }

  &__banner {
    width: 54%;
    height: 100%;

    position: relative;
    z-index: 4;

    padding-bottom: 100px;

    @media screen and(max-width: 1250px){
      width: 50%;
    }

    @media screen and(max-width: 991px){
      height: auto;
      width: 100%;
      padding-bottom: 0;
      margin-bottom: 40px;
    }

    &-inner {
      height: 100%;
      padding-top: 100px;

      position: relative;


      &:before {
        content: '';
        width: 102px;
        height: 64px;
        display: block;

        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;

        background-image: url("../images/svg/line4.svg");
        background-repeat: no-repeat;

        @media screen and(max-width: 1250px){
          width: 70px;

          background-size: contain;
        }
      }

      &:after {
        content: '';
        width: 175px;
        height: 166px;
        display: block;

        position: absolute;
        bottom: 30px;
        right: -30px;
        z-index: 2;

        background-image: url("../images/svg/line5.svg");
        background-repeat: no-repeat;

        @media screen and(max-width: 1250px){
          width: 100px;
          bottom: 0;

          background-size: contain;
        }
      }

      @media screen and(max-width: 991px){
        height: auto;
        padding-top: 0;

        &:before,
        &:after {
          display: none;
        }
      }
    }

    &-tx {
      height: 20%;
      width: 495px;
      margin: auto;

      position: relative;
      z-index: 5;

      &.tw {
        width: 670px;

        @media screen and(max-width: 1350px){
          width: 505px;

          h1 {
            font-size: 36px;
          }
        }
      }

      @media screen and(max-width: 1050px){
        width: 400px;
      }


      @media screen and(max-width: 991px){
        display: none;
      }

      p {
        margin-bottom: 0;

        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: $black2;
      }
    }

    &-list {
      height: 70%;

      position: relative;

      @media screen and(max-width: 991px){
        height: auto;
        display: none;
      }

      &:after {
        content: '';
        width: 93px;
        height: 50px;
        display: block;

        position: absolute;
        top: -44px;
        right: -51px;
        z-index: 2;

        background-image: url("../images/svg/line2.svg");
        background-repeat: no-repeat;
      }
    }

    &-foto {
      display: inline-block;

      position: absolute;
      z-index: 2;

      &.on {
        top: -102px;
        right: 20%;
        z-index: 4;

        @media screen and(max-width: 1250px){
          img {
            max-width: 200px;
          }
        }

        @media screen and(max-width: 1050px){
          right: 10%;
        }
      }


      &.tw {
        top: 12px;
        left: 0;

        @media screen and(max-width: 1250px){
          top: 50px;

          img {
            max-width: 150px;
          }
        }
      }

      &.th {
        top: 35%;
        left: 23%;

        @media screen and(max-width: 1575px){
          left: 19%;
        }

        @media screen and(max-width: 1400px){
          top: 54%;
          left: 9%;
        }

        @media screen and(max-width: 1250px){
          left: 15%;

          img {
            max-width: 250px;
          }
        }

        @media screen and(max-width: 1050px){
          left: 10%;
        }
      }

      &.fr {
        right: 73px;
        top: 42%;

        @media screen and(max-width: 1575px){
          right: 30px;
        }

        @media screen and(max-width: 1400px){
          top: 43%;
          right: 15px;
        }

        @media screen and(max-width: 1250px){
          img {
            max-width: 180px;
          }
        }
      }

      &-line {
        .on & {
          position: absolute;
          bottom: 59px;
          left: -47px;

          @media screen and(max-width: 1250px){

          }
        }

        .th & {
          position: absolute;

          bottom: -59px;
          left: -119px;

          @media screen and(max-width: 1250px){
            left: -70px;
            bottom: -20px;

            img {
              max-width: 150px;
            }
          }
        }
      }
    }
  }

  &__form {
    width: 46%;

    position: relative;

    @media screen and(max-width: 1250px){
      width: 50%;
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;

      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;

      background: $bg;
    }


    @media screen and(max-width: 991px){
      width: 100%;

      &:after {
        display: none;
      }
    }


    &-inner {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      z-index: 3;
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      font-weight: 600;
      font-size: 28px;
      line-height: 150%;
      color: $black2!important;
      text-align: center;

      img {
        margin-right: 13px;

        @media screen and(max-width: 991px){
          display: none;
        }
      }

      @media screen and(max-width: 991px){
        width: 200px;
        margin: 0 auto 25px;

        font-family: $font-family2;
        line-height: 36px;
      }
    }

    &-main {
      width: 517px;
      margin: auto;
      padding: 50px;

      background: $white;
      border-radius: 12px;

      @media screen and(max-width: 1250px){
        width: 400px;
      }

      @media screen and(max-width: 991px){
        max-width: 700px;
        padding: 50px 0;
        width: 100%;
      }

      &-tx {
        max-width: 300px;
        margin: -15px auto 20px;

        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: $black3;
        text-align: center;
      }

      &-tt {
        max-width: 300px;
        margin: 20px auto 0;

        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: $gray;
        text-align: center;

        @media screen and(max-width: 991px){
          margin-top: 10px;
          max-width: 200px;

          font-size: 12px;
          line-height: 150%;
        }
      }
    }
  }
}

.form {
  &__cell {
    margin-bottom: 12px;

    position: relative;

    .toggle-password {
      width: 20px;
      height: 20px;

      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 20px;
      z-index: 2;

      background-image: url("../images/svg/ye.svg");
      background-repeat: no-repeat;
      cursor: pointer;

      &.fa {
        background-image: url("../images/svg/ye2.svg");
      }
    }

    .er {
      display: block;
      margin-top: 4px;

      font-size: 14px;
      line-height: 150%;
      color: $red;
    }

    &.ch {
      position: relative;

      input{
        &[type="checkbox"] {
          display: none;

          & + label {
            display: block;
            padding-left: 25px;

            position: relative;

            font-size: 14px;
            line-height: 150%;
            color: $black2;
            cursor: pointer;

            &:before {
              content: '';
              width: 16px;
              height: 16px;
              display: block;

              position: absolute;
              top: 1px;
              left: 0;
              z-index: 2;

              border-radius: 2px;
              border: 1px solid $blue;
            }
          }

          &:checked {
            & + label {
              &:before {
                background-color: $blue;
                background-image: url("../images/svg/check2.svg");
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }
        }
      }
    }
  }

  &__bottom {
    margin-top: 20px;

    @media screen and(max-width: 991px){
      margin-top: 16px;
    }

    .button {
      display: flex;
      width: 100%;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.input {
  padding: 17px 24px;
  height: 60px;
  width: 100%;

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: $black2;

  border: 1px solid $bord;
  box-sizing: border-box;
  border-radius: 12px;

  &::-webkit-input-placeholder { color: $gray; }
  &:-ms-input-placeholder { color: $gray; }
  &::placeholder { color: $gray; }

  .form__cell_error & {
    border-color: $red;
  }

  @media screen and(max-width: 991px){
    height: 56px;

    font-size: 14px;
    line-height: 150%;
  }
}

.textarea {
  padding: 17px 24px 30px;
  height: 129px;
  width: 100%;

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: $black2;

  border: 1px solid $bord;
  box-sizing: border-box;
  border-radius: 12px;
  resize: none;

  &::-webkit-input-placeholder { color: $gray; }
  &:-ms-input-placeholder { color: $gray; }
  &::placeholder { color: $gray; }

  &-nb {
    font-size: 14px;
    line-height: 150%;
    color: $gray;

    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 2;
  }
}

.header {
  margin-bottom: 30px;

  background: $white;

  @media screen and(max-width: 991px) {
    display: none;
  }

  &-mob {
    display: none;

    @media screen and(max-width: 991px) {
      display: block;

      &__user {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 100px;
        background: $white;

        overflow: hidden;
      }
    }
  }

  &__inner {
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    display: inline-block;
    vertical-align: middle;
  }

  &__exit {
    display: inline-flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 7px;

      use {
        color: $black2;
      }
    }

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $black2;
    }
  }

  &__search {
    display: inline-block;
    vertical-align: middle;
    margin-left: 60px;

    @media screen and(max-width: 1250px) {
      .tw & {
        margin-left: 20px;
      }
    }


    &-inner {
      display: flex;
      align-items: center;
      width: 710px;

      @media screen and(max-width: 1450px) {
        .tw & {
          width: 500px;
        }
      }

      @media screen and(max-width: 1250px) {
        .tw & {
          width: 350px;
        }
      }

      @media screen and(max-width: 1200px) {
        width: 540px;
      }

      @media screen and(max-width: 1050px) {
        .tw & {
          width: 300px;
        }
      }
    }

    input {
      width: calc(100% - 52px);
      height: 42px;
      padding: 10px 17px;

      font-size: 14px;
      line-height: 150%;
      border: none;
      background: $bg2;
      border-radius: 6px 0 0 6px;
    }

    .button {
      width: 52px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      background: $blue;
      border-radius: 0 6px 6px 0;

      &:hover {
        background: $blue3;
      }
    }
  }

  &__right {
    .button {
      display: inline-flex;
      height: 42px;
      padding: 11px 19px;

      border-radius: 6px;
    }
  }

  &__user {
    display: inline-flex;
    align-items: center;
    padding-right: 15px;
    margin-left: 30px;

    position: relative;

    cursor: pointer;

    @media screen and(max-width: 1050px) {
      .tw & {
        margin-left: 10px;
      }
    }

    &:after {
      content: '';
      width: 8px;
      height: 15px;
      display: block;

      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;

      background-image: url("../images/svg/arrow.svg");
      background-repeat: no-repeat;
      background-size: 8px 15px;
      transform: rotate(-90deg);
      transition: 0.3s;

    }

    &.open {
      &:after {
        transform: rotate(90deg);
      }
    }

    &-foto {
      width: 38px;
      height: 38px;

      border-radius: 100px;
    }

    &-name {
      width: 141px;
      overflow: hidden;

      white-space: nowrap;
      text-overflow: ellipsis;

      padding-left: 12px;

      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $black2;
    }

    &-top {
      display: inline-flex;
      align-items: center;
    }

    &-drop {
      display: none;
      width: 250px;
      padding: 15px;

      position: absolute;
      top: 55px;
      right: 0;
      z-index: 10;

      background: $white;
      border-radius: 12px;
      border: 1px solid $bord;
      box-sizing: border-box;

      @media screen and(max-width: 991px) {
        top: 91px;
        right: 11px;
      }

      .open & {
        display: block;
      }

      &-item {
        display: block;

        margin-bottom: 10px;
        font-size: 16px;
        line-height: 24px;
        color: $black2;

        &:last-of-type {
          margin-bottom: 0;
        }

        &:hover {
          color: $blue3;
        }
      }
    }

    &-search {
      margin-bottom: 15px;

      &-inner {
        position: relative;

        input {
          height: 45px;
          width: 100%;
          padding: 5px 45px 5px 10px;

          font-size: 16px;
          line-height: 20px;

          border: 1px solid $bord;
          border-radius: 12px;
          background: $white;

          &:focus {
            border-color: $blue;
          }
        }

        .button {

          position: absolute;
          top: 0;
          right: 0;

          background: transparent;
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}

.wrapper {
  margin-bottom: 60px;

  @media screen and(max-width: 991px) {
    padding-top: 40px;
    margin-bottom: 0;
    height: 100vh;
    min-height: 100%;
  }
}

.results {
  @media screen and(max-width: 991px) {
    padding: 23px 0 60px;
    height: 100%;

    background: $white;
    border-radius: 30px 30px 0 0;

    .container,
    .results__inner {
      height: 100%;
    }
  }

  &-mb {
    display: none;

    @media screen and(max-width: 991px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      margin-bottom: 22px;
    }

    &__title {
      margin-bottom: 0;
      color: $white;
      font-size: 24px;
      line-height: 115%;
      font-family: $font-family2;

      a {
        display: none;

        @media screen and(max-width: 991px) {
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px;
        }
      }
    }
  }


  &__inner {
    display: flex;
    align-items: flex-start;

    position: relative;
    z-index: 3;
  }

  &__menu {
    width: 280px;
    padding: 15px 0;

    background: $white;
    border-radius: 6px;

    @media screen and(max-width: 1200px){
      width: 240px;
    }

    @media screen and(max-width: 991px) {
      display: none;
    }

    ul {
      li {
        a {
          display: flex;
          align-items: center;
          padding: 12px 18px;

          span {
            width: calc(100% - 24px);
            padding-left: 10px;

            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            color: $black2;
            transition: 0.3s;
          }

          svg {
            width: 24px;
            height: 24px;

            position: relative;
            top: -2px;

            use {
              color: $black2;
            }
          }

          &:hover {
            svg use, span {
              color: $blue3;
            }
          }
        }

        &.active {
          a {
            background: $blue2;

            svg use, span {
              color: $blue3;
            }
          }
        }
      }
    }
  }

  &__content {
    width: calc(100% - 280px);
    padding-left: 24px;

    @media screen and(max-width: 1200px){
      width: calc(100% - 240px);
    }

    @media screen and(max-width: 991px) {
      width: 100%;
      padding-left: 0;
    }

    .tw & {
      width: 100%;
      padding-left: 0;
    }

    h1 {
      margin-bottom: 15px;

      font-weight: 600;
      font-size: 28px;
      line-height: 150%;
      font-family: $font-family;

      @media screen and(max-width: 991px) {
        display: none;

        margin-bottom: 22px;
        color: $white;
        font-size: 24px;
        line-height: 115%;
        font-family: $font-family2;
      }
    }
  }

  &__tbl {
    &.top {
      margin-bottom: 12px;

      @media screen and(max-width: 991px) {
        display: none;
      }

      .results__tbl-row {
        cursor: default;

        &:after {
          display: none!important;
        }
      }

      .results__tbl-cell {
        padding: 10px 25px;

        &:first-of-type {
          padding: 10px 0;
        }

        .tw & {
          &:nth-of-type(1) {
            @media screen and(max-width: 991px) {
              display: none;
            }
          }
          &:nth-of-type(2) {
            width: 36%;

            @media screen and(max-width: 1150px) {
              width: 31%;
            }
          }
          &:nth-of-type(3) {
            width: 11%;

            @media screen and(max-width: 1150px) {
              width: 12%;
            }
          }
          &:nth-of-type(4) {
            width: 11%;

            @media screen and(max-width: 1150px) {
              width: 12%;
            }
          }
          &:nth-of-type(5) {
            width: 11%;

            @media screen and(max-width: 1150px) {
              width: 12%;
            }
          }
          &:nth-of-type(6) {
            width: 11%;

            @media screen and(max-width: 1150px) {
              width: 12%;
            }
          }
          &:nth-of-type(7) {
            width: 32%;

          }
        }
      }
    }

    &-row {
      padding: 0 18px;
      margin-bottom: 2px;
      display: flex;
      align-items: center;

      position: relative;

      cursor: pointer;
      background: $white;
      border-radius: 6px;

      @media screen and(max-width: 991px) {
        padding: 15px 18px 5px;

        background: $blue5;
        border-radius: 12px;
        flex-wrap: wrap;
        margin-bottom: 10px;

        &:after {
          display: none!important;
        }
      }

      &:after {
        content: '';
        width: 5px;
        height: 100%;
        display: block;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        background: $blue;
        border-radius: 6px 0 0 6px;
        opacity: 0;
        transition: 0.3s;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }

    &-cell {
      padding: 21px 25px;

      @media screen and(max-width: 991px) {
        padding: 0;
      }

      &:nth-of-type(1) {
        width:  16px;
        padding-left: 0;
        padding-right: 0;

        @media screen and(max-width: 991px) {
          display: none;
        }
      }
      &:nth-of-type(2) {
        width: 50%;

        @media screen and(max-width: 1350px){
          width: 45%;
          margin-bottom: 5px;
        }

        @media screen and(max-width: 1120px){
          width: 43%;
        }

        @media screen and(max-width: 991px) {
          width: 100%;
        }
      }
      &:nth-of-type(3) {
        width: 17%;

        @media screen and(max-width: 991px) {
          width: 50%;
        }
      }
      &:nth-of-type(4) {
        width: 17%;

        @media screen and(max-width: 991px) {
          display: none;
        }
      }
      &:nth-of-type(5) {
        width: 12%;


        .results__tbl-nb {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        @media screen and(max-width: 1350px){
          width: 17%;
        }

        @media screen and(max-width: 1120px){
          width: 19%;
        }

        @media screen and(max-width: 991px) {
          width: 50%;

          text-align: right;
        }
      }

      .tw & {
        &:nth-of-type(1) {
          @media screen and(max-width: 991px) {
            display: none;
          }
        }
        &:nth-of-type(2) {
          width: 38%;

          @media screen and(max-width: 1150px) {
            width: 34%;
          }

          @media screen and(max-width: 991px) {
            width: 100%;
          }
        }
        &:nth-of-type(3) {
          width: 11%;

          @media screen and(max-width: 1150px) {
            width: 12%;
          }

          @media screen and(max-width: 991px) {
            display: none;
          }
        }
        &:nth-of-type(4) {
          width: 11%;

          @media screen and(max-width: 1150px) {
            width: 12%;
          }

          @media screen and(max-width: 991px) {
            width: 50%;
          }
        }
        &:nth-of-type(5) {
          width: 11%;

          @media screen and(max-width: 1150px) {
            width: 12%;
          }

          @media screen and(max-width: 991px) {
            display: none;
          }
        }
        &:nth-of-type(6) {
          width: 11%;

          @media screen and(max-width: 1150px) {
            width: 12%;
          }

          @media screen and(max-width: 991px) {
            width: 50%;
          }
        }
        &:nth-of-type(7) {
          width: 32%;

          text-align: right;

          @media screen and(max-width: 991px) {
            width: 50%;

            text-align: right;
          }
        }
      }
    }

    &-cl {
      margin: 1px 3px;
      display: inline-block;
      vertical-align: middle;

      position: relative;

      &:after {
        content: '';
        width: 20px;
        height: 20px;

        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 10px;
        z-index: 2;

        background-image: url("../images/svg/calendar.svg");
        background-repeat: no-repeat;
      }

      &-in {

      }

      input {
        width: 156px;
        height: 40px;
        padding: 0 15px;

        border: 1px solid $bord;
        border-radius: 6px;
      }
    }

    &-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 37px;
      padding: 7px 33px;
      margin-top: 2px;

      background: $blue2;
      border-radius: 6px;
      color: $blue3;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      margin-left: 6px;

      &:hover {
        background: $blue;
        color: $white;
      }

      &.rose {
        color: $red2;
        background: $red3;

        &:hover {
          background: $red2;
          color: $white;
        }
      }

      @media screen and(max-width: 991px) {
        padding: 0;

        background: transparent!important;
        color: $blue3!important;

        &:before {
          content: '';
          width: 24px;
          height: 24px;
          display: inline-flex;

          background-image: url("../images/svg/upl.svg");
          background-repeat: no-repeat;
          background-position: center;
        }

        .tw & {
          &:before {
            display: none;
          }
        }
      }
    }

    &-top {
      span {
        display: inline-block;
        margin-right: 5px;

        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: $black2;
      }
    }

    &-title,
    &-nb {
      font-size: 16px;
      line-height: 150%;
      color: $black3;
    }

    &-nb {
      @media screen and(max-width: 991px) {
        color: $gray3;
      }
    }

    &-check {
      width: 16px;
      height: 16px;

      input {
        &[type="checkbox"] {
          display: none;

          & + label {
            display: block;
            padding-left: 18px;

            position: relative;

            font-size: 12px;
            line-height: 130%;
            cursor: pointer;

            &:before {
              content: '';
              width: 16px;
              height: 16px;
              display: block;

              position: absolute;
              top: 1px;
              left: 0;
              z-index: 2;

              border-radius: 2px;
              border: 1px solid $gray2;

              .check & {
                background-image: url("../images/svg/check2.svg");
                background-repeat: no-repeat;
                background-size: 10px 8px;
                background-position: center;
                background-color: $blue;
                border-color: $blue;
              }
            }

          }

          &:checked {
            & + label {
              &:before {
                background-image: url("../images/svg/check2.svg");
                background-repeat: no-repeat;
                background-size: 10px 8px;
                background-position: center;
                background-color: $blue;
                border-color: $blue;
              }
            }
          }
        }
      }
    }

    &-bottom {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: right;

      &-mob {
        display: none;
        margin-top: 20px  ;

        .button {
          width: 100%;
          display: flex;
        }
      }

      @media screen and(max-width: 991px) {
        display: none;

        &-mob {
          display: block;
        }
      }
    }
  }
}

.pages {
  &__tt {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;

    font-size: 16px;
    line-height: 150%;
    color: $black2;
  }

  &__list {
    width: 90px;
    display: inline-block;
    vertical-align: middle;

    position: relative;

    &-inner {
      width: 63px;
      padding: 5px 15px;
      display: none;

      position: absolute;
      top: 55px;
      left: 0;
      z-index: 2;

      background: $white;
      border-radius: 6px;

      .open & {
        display: block;
      }

      a {
        display: block;

        font-size: 16px;
        line-height: 150%;
        color: $black3;

        &:hover {
          color: $red2;
        }
      }
    }
  }

  &__title {
    width: 90px;
    height: 46px;
    display: inline-flex;
    align-items: center;

    position: relative;

    background: $white;
    border-radius: 8px;
    cursor: pointer;

    &:after {
      content: '';
      width: 12px;
      height: 12px;
      display: block;

      position: absolute;
      top: 0;
      right: 20px;
      bottom: 0;
      margin: auto;
      z-index: 2;

      background-image: url("../images/svg/arrow2.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.3s;

      .open & {
        transform: rotate(180deg);
      }
    }

    span {
      display: flex;
      align-items: center;
      padding: 16px;

      font-size: 16px;
      line-height: 150%;
      color: $black2;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  &-bl {
    margin-left: 50px;
  }

  .page {
    &-item {
      margin: 0 3px;

      a, span {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;

        font-size: 16px;
        line-height: 150%;
        color: $black2;
        border: none;
        box-shadow: none!important;
        background: transparent!important;

        &:hover {
          color: $blue;
        }
      }

      span {
        cursor: inherit!important;
        color: $black2;
        background: transparent;

        &:hover {
          color: $black2;
        }
      }

      &.active {
        a {
          color: $blue;
        }
      }

      &.prev,
      &.next {
        margin: 0 15px;
        width: 48px;
        height: 48px;

        position: relative;

        &:hover {
          background: transparent;
        }



        .ar {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;

          position: absolute;
          top: 0;

          border-radius: 8px!important;
          background: $white!important;
        }

        a {
          position: initial;

          width: 48px;
          height: 48px;

          background: transparent!important;

          &:hover {
            svg {
              use {
                color: $blue;
              }
            }
          }
        }

        svg {
          width: 12px;
          height: 12px;

          use {
            color: $black2;
          }
        }
      }


      &.prev {
        padding-left: 50px;
        margin-left: 0;

        .ar {
          left: 0;
        }

        svg {
         // transform: rotate(90deg);
        }
      }

      &.next {
        padding-right: 50px;
        margin-right: 0;

        .ar {
          right: 0;
        }

        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.profile {
  max-width: 708px;
  width: 100%;

  background: $white;
  border-radius: 6px;
  overflow: hidden;

  @media screen and(max-width: 991px) {
    max-width: 100%;
  }

  &__inner {
    padding: 27px 33px;

    @media screen and(max-width: 991px) {
      padding: 0;
    }
  }

  &__row {
    display: flex;
    margin-bottom: 13px;

    &.bottom {
      display: flex;
      align-items: center;
      justify-content: right;
      margin-bottom: 0;
      padding: 18px 33px;

      box-shadow: -3px 13px 75px -3px rgba(69, 46, 85, 0.1);
      border-radius: 6px;

      .button {
        padding: 11px 19px;
        height: 42px;
        margin-left: 14px;

        border-radius: 6px;
      }

      .link {
        display: none;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: $blue3;
        }

        svg {
          width: 24px;
          height: 24px;
          margin-right: 9px;

          use {
            color: $blue3;
          }
        }
      }

      @media screen and(max-width: 991px) {
        box-shadow: none;
        margin-bottom: 20px;
        padding: 0;
        width: 100%;

        position: absolute;
        bottom: 0;
        left: 0;

        &.bottom {
          margin-bottom: 0;
        }

        .button {
          display: none;
        }

        .link {
          display: table;
          margin: auto;
        }
      }
    }

    .er {
      margin-top: 3px;
      font-size: 14px;
      line-height: 150%;
      color: $red;

      @media screen and(max-width: 991px) {
        display: none;
      }
    }

    .tx {
      margin-top: 3px;
      font-size: 14px;
      line-height: 140%;
      color: $gray;
    }

    .txx {
      margin-top: 8px;

      font-size: 14px;
      line-height: 150%;
      color: $black2;

      a {
        color: $blue3;
        font-weight: 500;

        &:hover {
          color: $blue;
        }
      }
    }
  }

  &__title {
    width: 200px;
    padding-top: 17px;

    position: relative;

    @media screen and(max-width: 991px) {
      width: 100%;
      padding-top: 0;

      &:after {
        content: '';
        width: 11px;
        height: 22px;
        display: block;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        background-image: url("../images/svg/arrow.svg");
        background-repeat: no-repeat;
        transform: rotate(180deg);
      }
    }

    &-main {
      margin-bottom: 24px;

      font-weight: 600;
      font-size: 18px;
      line-height: 130%;
      color: $black2;

      @media screen and(max-width: 991px) {
        display: none;
      }
    }

    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: $black3;

      @media screen and(max-width: 991px) {
        color: $gray;
        font-size: 14px;
        line-height: 150%;
      }
    }

    &-mb {
      display: none;
      width: 100%;

      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: $black2!important;

      @media screen and(max-width: 991px) {
        display: block;

        &.mb {
          color: $blue3!important;
        }
      }
    }

  }

  &__inp {
    width: calc(100% - 200px);

    position: relative;

    @media screen and(max-width: 991px) {
      display: none;
    }

    .err & {
      input {
        border-color: $red;
      }
    }

    .button {
      height: 37px;
      padding: 8px 33px;

      position: absolute;
      top: 11px;
      right: 10px;
      z-index: 2;

      border-radius: 6px;

      font-size: 14px;
      line-height: 150%;
    }
  }
}

.modal {
  &-backdrop {
    background-color: rgb(28, 34, 35);
  }

  &-dialog {
    max-width: 500px;

    .w-1 & {
      max-width: 600px;
    }
  }

  &-body {
    padding: 25px 20px;
    border: none;
  }

  &-content {
    border-radius: 20px;
    border: none!important;

    .w-1 & {
      border-radius: 6px;
    }
  }

  &-title {
    margin-bottom: 15px;

    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: $black2;
    text-align: center;
  }

  &-inp {
    .input {
      margin-bottom: 10px;
    }

    .button {
      margin-top: 10px;
      display: flex;
      width: 100%;
    }

    &.error {
     .input {
       border-color: $red;
     }
    }

    .er {
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: $red;
    }
  }

  &-txx {
    margin-top: 10px;

    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: $gray;
  }

  &-tx {
    margin-top: 15px;

    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: $gray;
    text-align: center;

    a {
      color: $blue;

      &:hover {
        clear: $black3;
      }
    }

    &-main {
      margin-bottom: 15px;

      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: $black2;

      text-align: center;

        @media screen and(max-width: 400px) {
        br {
          display: none;
        }
      }
    }
  }

  &-form {
    padding: 6px 10px 0;

    &__row {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      @media screen and(max-width: 500px) {
        display: block;
      }

      &.bottom {
        padding: 20px 0;
        margin: 20px -20px -20px;
        justify-content: right;

        text-align: right;
        box-shadow: -3px 13px 75px -3px rgba(69, 46, 85, 0.1);

        .button {
          padding: 11px 20px;
          height: 42px;
          margin-left: 20px;

          border-radius: 6px;

          @media screen and(max-width: 500px) {
            margin-left: 0;
          }
        }
      }
    }

    &__title {
      width: 170px;
      padding-right: 10px;

      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: $black2;

      @media screen and(max-width: 500px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &__inp {
      width: calc(100% - 170px);

      position: relative;

      @media screen and(max-width: 500px) {
        width: 100%;
      }

      &.calendar {
        &:after {
          content: '';
          width: 20px;
          height: 20px;
          display: block;

          position: absolute;
          top: 0;
          bottom: 0;
          right: 20px;
          margin: auto;

          background-image: url("../images/svg/calendar.svg");
          background-repeat: no-repeat;
        }
      }
    }
  }

  &-text {
    text-align: center;

    &-title {
      margin-bottom: 25px;

      color: $black2;
      font-size: 28px;
      line-height: 34px;
      font-weight: 500;
    }

    p {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 22px;
      color: $black2;
    }

    .button {
      margin-top: 30px;
    }
  }
}

#files-area{
  margin: 0 auto;
}
.file-block{
  margin: 5px;
  color: initial;
  display: inline-flex;
  padding-right: 30px;

  position: relative;

  &:before {
    content: '';
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;

    background-image: url("../images/svg/file.svg");
    background-repeat: no-repeat;
  }

  & > span.name{
    padding-right: 10px;
    padding-left: 10px;

    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    display: block;
  }
}
.file-delete{
  position: absolute;
  right: 0;


  margin-right: 3px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;

    use {
      color: $black2;
      stroke: $black2;
    }
  }

  &:hover {
    svg {
      use {
        color: $blue;
        stroke: $blue;
      }
    }
  }
}

.fl {
  .open & {
    display: none;
  }

  label {
    width: 100%;
  }

  .bt {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;

    background-color: $blue2;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $blue3;

    svg {
      width: 17px;
      height: 17px;
      margin-right: 10px;

      use {
        color: $blue3;
      }
    }

    &:hover {
      background: $blue3;
      color: $white;

      svg {
        use {
          color: $white;
        }
      }
    }
  }
}




.ui-datepicker  {
  .ui-datepicker-title {

  }

  td {
    &[data-handler="selectDay"] {

    }
  }

  .ui-state-default {
    background: $white!important;
    border: none!important;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: $black2;
    font-family: $font-family;

    &.ui-priority-secondary {
      color: $gray4;
    }

    &.ui-state-active {
      color: $red2;
    }
  }

  .ui-datepicker-header {
    background: $white!important;
    border: none!important;
  }

  &-prev,
  &-next {
    background-image: url("../images/svg/arrow.svg")!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: 10px 10px!important;
    background-color: $white!important;
    border: none!important;

    .ui-icon {
      display: none!important;
    }
  }

  &-next {
    transform: rotate(180deg);
  }

  &-year {
    font-family: $font-family;
  }
}

.ui-widget.ui-widget-content {
  border: none!important;
  border-radius: 6px!important;
  box-shadow: -3px 13px 75px -3px rgba(69, 46, 85, 0.05);
  margin-top: 10px;

  &:before {
    content: '';
    width: 16px;
    height: 8px;
    display: block;

    position: absolute;
    top: -6px;
    left: 20px;
    z-index: 2;

    background-image: url("../images/ar.png");
    background-repeat: no-repeat;
  }
}



.select2-container--default .select2-selection--single {
  width: 100%;
  height: 60px!important;
  padding: 17px 24px;
  border: 1px solid #F0F0F0;
  border-radius: 12px;
}
.select2-container {
  width: 100%!important;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 60px!important;
  right: 4px!important;
}

.select2-container--open .select2-dropdown--below {
  border-color:  #F0F0F0!important;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background: transparent!important;

  color: $blue!important;
}

.select2-container--default .select2-results__option--selected {
  background: transparent!important;
}